'use client';
import { logger } from '@lichtblick/logger';

import { collectServicesConsent, hasNonEssentialConsentBeenWithdrawn } from './ConsentManager.helpers';
import {
  CONSENT_STATUS_CHANGE,
  ConsentManagerType,
  ServiceData,
  ServicesConsent,
  UC_UI_INITIALIZED,
  UCConsentEvent,
} from './ConsentManager.types';

const UC_UI_INITIALIZATION_TIMEOUT = 5 * 1000;

/**
 * Manages user consent for various services by wrapping Usercentrics.
 * Handles initialization, consent tracking as well as fallbacks if Usercentrics is blocked.
 */
export class ConsentManager implements ConsentManagerType {
  initPromise: Promise<void> | null = null;
  isUcInitialized: boolean = false;
  private prevServicesConsent: ServicesConsent | null = null;

  constructor() {
    this.initPromise = this.init();
  }

  public init = async (): Promise<void> => {
    if (typeof window === 'undefined' || this.isUcInitialized) {
      return Promise.resolve();
    }

    return new Promise<void>((resolve) => {
      const timeout = setTimeout(() => {
        window.removeEventListener(UC_UI_INITIALIZED, onInitialized);

        logger.info('Failed to initilize usercentrics due to timeout.');

        return Promise.resolve();
      }, UC_UI_INITIALIZATION_TIMEOUT);

      const onInitialized = async (): Promise<void> => {
        if (this.isUcInitialized) {
          resolve();

          return;
        }

        try {
          const consent = await window.__ucCmp?.getConsentDetails();

          this.prevServicesConsent = collectServicesConsent(consent?.services);

          clearTimeout(timeout);
          window.addEventListener(CONSENT_STATUS_CHANGE, this.handleConsentChange);
          window.removeEventListener(UC_UI_INITIALIZED, onInitialized);
        } catch (error) {
          logger.warn('Failed to set up usercentrics handler');
          window.addEventListener(CONSENT_STATUS_CHANGE, location.reload);
        } finally {
          this.isUcInitialized = true;
          resolve();
        }
      };

      window.addEventListener(UC_UI_INITIALIZED, onInitialized);
    });
  };

  /**
   * Retrieves the consent status for a specific service.
   *
   * @param serviceName - The name of the service to check consent for
   * @param changeEvent - Optional CustomEvent containing updated consent information
   * @returns Promise resolving to:
   *  - `true` if consent is given
   *  - `false` if consent is denied
   *  - `undefined` if consent manager is not initialized or service not found
   *
   */
  public getConsentStatus = async (serviceName: string): Promise<boolean | undefined> => {
    if (!this.isUcInitialized) {
      return;
    }

    const { service } = (await this.getService(serviceName)) || {};

    return service?.consent?.given;
  };

  /**
   * Retrieves the Usercentrics controller ID.
   *
   * @returns Promise resolving to:
   *  - The controller ID string if consent manager is initialized
   *  - `undefined` if consent manager is not initialized
   *
   */
  public getControllerId = async (): Promise<string | undefined> => {
    if (!this.isUcInitialized) {
      return;
    }

    return window.__ucCmp?.getControllerId();
  };

  private handleConsentChange = (event: UCConsentEvent): void => {
    const currentServicesConsent = collectServicesConsent(event.detail.services);

    if (
      this.prevServicesConsent &&
      hasNonEssentialConsentBeenWithdrawn(this.prevServicesConsent, currentServicesConsent)
    ) {
      location.reload();
    }

    this.prevServicesConsent = currentServicesConsent;
  };

  private getService = async (
    serviceName: string,
  ): Promise<{ service: ServiceData; serviceId: string } | undefined> => {
    if (!this.isUcInitialized) {
      return;
    }

    const services = (await window.__ucCmp?.getConsentDetails())?.services || {};
    const serviceData = Object.entries(services).find(([_, service]) => service.name === serviceName);

    return serviceData ? { serviceId: serviceData[0], service: serviceData[1] } : undefined;
  };
}
